<template>
  <div>
    <AppTable v-if="isEdit" :rows="rows" :columns="columns" :is-loading="requestInProgress" :is-pagination="false">
      <template #prependFilters>
        <b-row>
          <b-col>
            <div class="d-flex  justify-content-end flex-wrap mb-2">
              <b-button class="mt-auto" type="button" variant="primary" @click="createFee">
                <feather-icon icon="PlusIcon" size="14" />
                <span> Create fee </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </template>

      <template #default="{ column, row, formattedRow }">
        <span v-if="column.field === 'action'" class="d-flex">
          <b-button class="mt-auto text-white ml-1" type="button" variant="primary" @click="editFee(row)">
            <span class="text-white"> Edit </span>
          </b-button>
        </span>

        <span v-else class="flex-center-align">
          {{ formattedRow[column.field] }}
        </span>
      </template>
    </AppTable>

    <b-card>
      <div>
        <validation-observer ref="provider">
          <b-form>
            <b-row>
              <b-col md="4" xl="4" class="mb-1">
                <validation-provider #default="{ errors }" name="Fiat" rules="required">
                  <AssetFilter
                    v-model="form.fiatId"
                    label="Currency"
                    placeholder="Fiat currency"
                    @change="changeAsset"
                    :is-crypto-currency="false"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>

              <b-col md="6">
                <b-form-group label="Name" label-for="name">
                  <validation-provider #default="{ errors }" name="name" rules="required">
                    <b-form-input
                      v-model="form.name"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      placeholder="name"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!--              <b-col md="6">-->
              <!--                <b-form-group label="Parent id " label-for="parentId">-->
              <!--                  <validation-provider #default="{ errors }" name="parentId">-->
              <!--                    <b-form-input-->
              <!--                      v-model="form.parentId"-->
              <!--                      type="text"-->
              <!--                      :state="errors.length > 0 ? false : null"-->
              <!--                      placeholder="parent id"-->
              <!--                    />-->

              <!--                    <small class="text-danger">{{ errors[0] }}</small>-->
              <!--                  </validation-provider>-->
              <!--                </b-form-group>-->
              <!--              </b-col>-->

              <b-col md="6">
                <b-form-group label="Time Min (Seconds)" label-for="transactionLimitTimeMin">
                  <validation-provider #default="{ errors }" name="transactionLimitTimeMin" rules="required">
                    <b-form-input
                      v-model="form.transactionLimitTimeMin"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      placeholder="seconds"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group label="Time Max (Seconds)" label-for="transactionLimitTimeMax">
                  <validation-provider #default="{ errors }" name="transactionLimitTimeMax" rules="required">
                    <b-form-input
                      v-model="form.transactionLimitTimeMax"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      placeholder="seconds"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group label="Limit cost" label-for="transactionLimitCost">
                  <validation-provider #default="{ errors }" name="transactionLimitCost" rules="required">
                    <b-form-input
                      v-model.number="form.transactionLimitCost"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Limit cost "
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group label="Icon" label-for="icon">
                  <validation-provider #default="{ errors }" name="icon">
                    <b-form-input
                      v-model="form.icon"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Icon"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group label="Status" label-for="isActive">
                  <validation-provider #default="{ errors }" name="isActive">
                    <v-select
                      v-model="form.status"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="text"
                      :options="statusOptions"
                      placeholder="set status"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Action Buttons -->
            <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" @click.prevent="save">
              {{ this.paymentMethodId ? 'Edit' : 'Create' }}
            </b-button>
          </b-form>
        </validation-observer>
      </div>
    </b-card>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BCard, BFormCheckbox } from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapActions } from 'vuex'

import waitRequest from '@/mixins/waitRequest'
import validationError from '@/mixins/validationError'

import { feesTable } from '@/views/apps/payment-methods/config/tableConfig'

// eslint-disable-next-line
import { required } from '@validations'
import AppTable from '@/components/AppTable.vue'
import vSelect from 'vue-select'
import AssetFilter from '@/components/containers/AssetFilter.vue'
export default {
  name: 'PaymentMethodsCreate',
  components: {
    AssetFilter,
    vSelect,
    AppTable,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BFormCheckbox,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
  },
  mixins: [waitRequest, validationError],
  data() {
    return {
      paymentMethodId: null,
      paymentMethodFees: [],
      form: {
        fiatId: null,
        name: '',
        // parentId: '',
        transactionLimitTimeMin: 0,
        transactionLimitTimeMax: 0,
        transactionLimitCost: 0,
        icon: '',
        status: '',
      },

      statusOptions: [
        { text: '--', isActive: null, id: 0 },
        { text: 'active', isActive: true, id: 1 },
        { text: 'not active', isActive: false, id: 2 },
      ],
    }
  },
  computed: {
    isEdit() {
      return Boolean(this.paymentMethodId)
    },

    rows() {
      return this.transformRows(this.paymentMethodFees || [])
    },

    columns() {
      return feesTable
    },
  },
  mounted() {
    this.initState()
  },
  methods: {
    ...mapActions({
      fetchPaymentMethodById: 'paymentMethods/fetchPaymentMethodById',
      createPaymentMethod: 'paymentMethods/createPaymentMethod',
      updatePaymentMethodById: 'paymentMethods/updatePaymentMethodById',
    }),

    initState() {
      this.registerValidator(this.$refs.provider)

      if (!this.$route.params.id) return

      this.paymentMethodId = this.$route.params.id
      this.getPaymentMethodById(this.paymentMethodId)
    },

    create() {
      this.waitRequest(() => {
        return this.createPaymentMethod({
          ...this.form,
          isActive: this.form.status.isActive,
          fiatId: this.form.fiatId.id,
        })
          .then(response => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Payment method is added',
                icon: 'PlusCircleIcon',
                variant: 'info',
              },
            })
            this.$router.push('/payment-methods')
            this.form = {};
          })
          .catch(this.checkErrors)
      })
    },

    update() {
      this.waitRequest(() => {
        return this.updatePaymentMethodById({
          id: this.paymentMethodId,
          form: {
            ...this.form,
            isActive: this.form.status.isActive,
            fiatId: this.form.fiatId.id,
          },
        })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Payment method is updated',
                icon: 'PlusCircleIcon',
                variant: 'info',
              },
            })
            this.$router.push('/payment-methods')
            this.form = {}
          })
          .catch(this.checkErrors)
      })
    },

    getPaymentMethodById(paymentMethodId) {
      this.fetchPaymentMethodById(paymentMethodId)
        .then(response => {
          this.paymentMethodFees = response.data?.paymentMethodFees
          const {
            name,
            transactionLimitTimeMin,
            transactionLimitTimeMax,
            transactionLimitCost,
            icon,
            isActive,
            fiat,
          } = response.data

          this.form = {
            name,
            fiatId: { ...fiat, labelName: fiat?.name || '' },
            transactionLimitTimeMin,
            transactionLimitTimeMax,
            transactionLimitCost,
            icon,
            status: this.statusOptions.find(item => item.isActive === isActive),
          }
        })
        .catch(this.checkErrors)
    },

    async save() {
      const isValid = await this.checkIsValidForm()
      if (this.requestInProgress || !isValid || this.once) return

      if (this.paymentMethodId) return this.update()

      this.create()
    },

    createFee() {
      this.$router.push({ path: `/payment-methods/fee/create/${this.paymentMethodId}` })
    },

    editFee(row) {
      this.$router.push({ path: `/payment-methods/${this.paymentMethodId}/fee/${row.coinId}`})
    },

    transformRows(rows) {
      return rows?.map(row => {
        const { emoji = '', name = '', network = '', id: coinId } = row.coin
        const coin = `${emoji || ''} ${name} ${network ? '/ ' + network : ''}`

        return {
          ...row,
          coinId,
          coin,
        }
      })
    },

    changeAsset(currency) {
      console.log('currency', currency)
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
