var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isEdit)?_c('AppTable',{attrs:{"rows":_vm.rows,"columns":_vm.columns,"is-loading":_vm.requestInProgress,"is-pagination":false},scopedSlots:_vm._u([{key:"prependFilters",fn:function(){return [_c('b-row',[_c('b-col',[_c('div',{staticClass:"d-flex  justify-content-end flex-wrap mb-2"},[_c('b-button',{staticClass:"mt-auto",attrs:{"type":"button","variant":"primary"},on:{"click":_vm.createFee}},[_c('feather-icon',{attrs:{"icon":"PlusIcon","size":"14"}}),_c('span',[_vm._v(" Create fee ")])],1)],1)])],1)]},proxy:true},{key:"default",fn:function(ref){
var column = ref.column;
var row = ref.row;
var formattedRow = ref.formattedRow;
return [(column.field === 'action')?_c('span',{staticClass:"d-flex"},[_c('b-button',{staticClass:"mt-auto text-white ml-1",attrs:{"type":"button","variant":"primary"},on:{"click":function($event){return _vm.editFee(row)}}},[_c('span',{staticClass:"text-white"},[_vm._v(" Edit ")])])],1):_c('span',{staticClass:"flex-center-align"},[_vm._v(" "+_vm._s(formattedRow[column.field])+" ")])]}}],null,false,3015218931)}):_vm._e(),_c('b-card',[_c('div',[_c('validation-observer',{ref:"provider"},[_c('b-form',[_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"md":"4","xl":"4"}},[_c('validation-provider',{attrs:{"name":"Fiat","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('AssetFilter',{attrs:{"label":"Currency","placeholder":"Fiat currency","is-crypto-currency":false},on:{"change":_vm.changeAsset},model:{value:(_vm.form.fiatId),callback:function ($$v) {_vm.$set(_vm.form, "fiatId", $$v)},expression:"form.fiatId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Name","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","state":errors.length > 0 ? false : null,"placeholder":"name"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Time Min (Seconds)","label-for":"transactionLimitTimeMin"}},[_c('validation-provider',{attrs:{"name":"transactionLimitTimeMin","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","state":errors.length > 0 ? false : null,"placeholder":"seconds"},model:{value:(_vm.form.transactionLimitTimeMin),callback:function ($$v) {_vm.$set(_vm.form, "transactionLimitTimeMin", $$v)},expression:"form.transactionLimitTimeMin"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Time Max (Seconds)","label-for":"transactionLimitTimeMax"}},[_c('validation-provider',{attrs:{"name":"transactionLimitTimeMax","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","state":errors.length > 0 ? false : null,"placeholder":"seconds"},model:{value:(_vm.form.transactionLimitTimeMax),callback:function ($$v) {_vm.$set(_vm.form, "transactionLimitTimeMax", $$v)},expression:"form.transactionLimitTimeMax"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Limit cost","label-for":"transactionLimitCost"}},[_c('validation-provider',{attrs:{"name":"transactionLimitCost","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","state":errors.length > 0 ? false : null,"placeholder":"Limit cost "},model:{value:(_vm.form.transactionLimitCost),callback:function ($$v) {_vm.$set(_vm.form, "transactionLimitCost", _vm._n($$v))},expression:"form.transactionLimitCost"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Icon","label-for":"icon"}},[_c('validation-provider',{attrs:{"name":"icon"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","state":errors.length > 0 ? false : null,"placeholder":"Icon"},model:{value:(_vm.form.icon),callback:function ($$v) {_vm.$set(_vm.form, "icon", $$v)},expression:"form.icon"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Status","label-for":"isActive"}},[_c('validation-provider',{attrs:{"name":"isActive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"text","options":_vm.statusOptions,"placeholder":"set status"},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_vm._v(" "+_vm._s(this.paymentMethodId ? 'Edit' : 'Create')+" ")])],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }